import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import * as c from '../../../common'
import { processErr } from '../../../common'

import * as Mui from '@mui/material'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { GoogleAuthProvider, signInWithCredential } from 'firebase/auth'

import * as Fire from '../../fire'
import Pad from '../../comps/Pad'
import { auth } from '../../fire'

import styles from './Login.module.css'

export interface LoginProps {}

const logo = c.LOGO_BIG
const logoDark = c.LOGO_BIG_DARK || c.LOGO_BIG

export default React.memo<LoginProps>(function Login() {
  const [authenticating, setAuthenticating] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [pass, setPass] = React.useState('')
  const [err, setErr] = React.useState('')

  const theme = Mui.useTheme()
  const location = useLocation()
  const navigate = useNavigate()

  const isDark = theme.palette.mode === 'dark'

  const [isLoad, setIsLoad] = React.useState(false)

  const [showPassword, setShowPassword] = React.useState(false)

  const from = (location.state as any)?.from?.pathname || '/'

  const resetError = React.useCallback(() => {
    setErr('')
  }, [])

  const handleEmailChange = React.useCallback((e) => {
    setEmail(e.target.value)
  }, [])

  const handlePassChange = React.useCallback((e) => {
    setPass(e.target.value)
  }, [])

  const handleSubmit = React.useCallback(async () => {
    try {
      setErr('')
      setAuthenticating(true)
      await Fire.signInWithEmailAndPassword(email, pass)
      navigate(from, { replace: true })
    } catch (e) {
      setErr(processErr(e))
      setAuthenticating(false)
    }
  }, [email, from, navigate, pass])

  const handleCallBackResponse = React.useCallback(
    (response: google.accounts.id.CredentialResponse) => {
      const googleCredential = GoogleAuthProvider.credential(
        response.credential
      )

      signInWithCredential(auth, googleCredential)
        .then(() => {
          setErr('')
          setAuthenticating(true)
          navigate(from, { replace: true })
        })
        .catch((error) => {
          setErr(processErr(error))
          setAuthenticating(false)
        })
    },
    [from, navigate]
  )

  React.useEffect(() => {
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Enter' && email !== '' && pass !== '') {
        handleSubmit()
      }
    })
  }, [email, pass, handleSubmit])

  React.useEffect(() => {
    google.accounts.id.initialize({
      callback: handleCallBackResponse,
      client_id: process.env['REACT_APP_G_CLIENT_ID']!
    })

    google.accounts.id.renderButton(
      document.getElementById('signWithGoogle') as HTMLElement,
      { theme: 'outline', size: 'large', type: 'standard' }
    )
  }, [err, handleCallBackResponse])

  React.useEffect(() => {
    setIsLoad(true)
  }, [isLoad, setIsLoad])

  const handleClickShowPassword = React.useCallback(
    () => setShowPassword((show) => !show),
    []
  )

  const handleMouseDownPassword = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
    },
    []
  )

  const endAdornmentPassVisible = React.useMemo(
    () => (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          edge="end"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          <VisibilityOff />
        </IconButton>
      </InputAdornment>
    ),
    [handleClickShowPassword, handleMouseDownPassword]
  )

  const endAdornmentPassHidden = React.useMemo(
    () => (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          edge="end"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          <Visibility />
        </IconButton>
      </InputAdornment>
    ),
    [handleClickShowPassword, handleMouseDownPassword]
  )

  if (err) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          justifyContent: 'center',
          width: '100vw',
          backgroundColor: 'rgba(0, 0, 0, 0.7)'
        }}
      >
        <Alert severity="error" sx={{ maxWidth: '300px' }}>
          {err}
        </Alert>
        <Pad amt={10} />
        <Button variant="contained" color="info" onClick={resetError}>
          Go Back
        </Button>
      </Box>
    )
  }

  return (
    <Box className={styles['container']}>
      <Box component={Paper} elevation={5} className={styles['login-box']}>
        <div className={styles['brand']}>
          <img alt="Logotype" src={isDark ? logoDark : logo} width={200} />
        </div>

        <Pad amt={30} />

        <Grid container alignItems="center">
          <Grid item xs={12}>
            <TextField
              className={styles['input']}
              disabled={authenticating}
              id="email"
              label="Email"
              onChange={handleEmailChange}
              type="email"
              value={email}
            />
          </Grid>

          <Grid item xs={12}>
            <Pad amt={24} />
          </Grid>

          <Grid item xs={12}>
            <FormControl className={styles['form-control']} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                className={styles['input']}
                disabled={authenticating}
                endAdornment={
                  showPassword
                    ? endAdornmentPassVisible
                    : endAdornmentPassHidden
                }
                id="outlined-adornment-password"
                label="Password"
                onChange={handlePassChange}
                type={showPassword ? 'text' : 'password'}
                value={pass}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Pad amt={30} />
        <Mui.Typography>
          Version: {process.env['REACT_APP_APP_VERSION']} (#
          {process.env['REACT_APP_COMMIT_SHORT_HASH']})
        </Mui.Typography>

        <Pad amt={30} />

        <Button
          className={styles['submit']}
          color="info"
          onClick={handleSubmit}
          variant="contained"
        >
          Login
        </Button>

        <Pad amt={48} />

        <Link to="/privacy-policy">
          <Mui.Typography>Privacy Policy</Mui.Typography>
        </Link>

        {/* <div className={styles['options']}>
          <label className={styles['remember-pass']} htmlFor="remember-pass">
            Remember me
            <input id="remember-pass" type="checkbox" />
          </label>

          <Pad amt={12} />

          <div className={styles['submit-or-forgot']}>
            

            <a className={styles['forgot-pass']} href="#">
              Forgot your password?
            </a>
          </div>
        </div> */}
      </Box>
    </Box>
  )
})

export interface CredentialResponse {
  aud: string
  azp: string
  email_verified: boolean
  email: string
  exp: number
  family_name: string
  given_name: string
  iat: number
  iss: string
  jti: string
  name: string
  nbf: number
  picture: string
  sub: string
}

export interface GoogleUser {
  email: string
}
