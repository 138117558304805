import React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'

import * as gs from '../gStyles'

import Text from './Text'

export type LabelProps = c.r<{
  alignRight?: boolean
  children: number | string | (number | string)[]
  danger?: boolean
  disabled?: boolean
  iconRight?: React.ReactElement | false
  on: c.Surface
  onPress?: c.VoidFn | false
  showAsterisk?: boolean
}>

export default React.memo<LabelProps>(function Label({
  alignRight,
  children,
  danger,
  disabled,
  iconRight,
  on,
  onPress,
  showAsterisk
}) {
  const styles = gs.useThemedStyleSheet(themedStyles, on)

  const Wrapper = onPress && !disabled ? rn.TouchableOpacity : rn.View

  return (
    // prettier-ignore
    // @ts-expect-error
    <Wrapper 
      onPress={!disabled && onPress}
      style={gs.rowSpaceBetween}
    >
      <Text
        ellipsizeMode="tail"
        style={(() => {
          if (disabled) {
            return alignRight
              ? styles.labelDisabledAlignRight
              : styles.labelDisabled
          }
          if (danger) {
            return alignRight
              ? styles.labelDangerAlignRight
              : styles.labelDanger
          }
          return alignRight ? styles.labelAlignRight : styles.label
        })()}
      >
        {children} {showAsterisk && <Text style={styles.required}>*</Text>}
      </Text>
      {iconRight}
    </Wrapper>
  )
})

const themedStyles = gs.ThemedStyleSheet.create((t, on) => {
  const label: rn.TextStyle = {
    color: t[on].color,
    fontFamily: t[on].fontFamily,
    fontSize: 18,
    fontWeight: '500',
    letterSpacing: 0.8,
    flexGrow: 1,
    ...gs.userSelectNone
  } as const
  const labelAlignRight = { ...label, ...gs.txtAlignRight }
  const danger = { color: t.danger }
  const labelDanger = { ...label, ...danger }
  const labelDangerAlignRight = { ...label, ...danger, ...gs.txtAlignRight }
  const labelDisabled = { ...label, ...gs.disabled }
  const labelDisabledAlignRight = {
    ...label,
    ...gs.disabled,
    ...gs.txtAlignRight
  }

  return {
    label,
    labelAlignRight,
    labelDanger,
    labelDangerAlignRight,
    labelDisabled,
    labelDisabledAlignRight,
    required: { color: t.third, fontFamily: t[on].fontFamily }
  }
})
