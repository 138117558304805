import React from 'react'
import {
  Modal,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  Text,
  View,
  useColorScheme
} from 'react-native'

import * as c from '../../common'
import { MAX_MPU_RATING, useMpuCalculator } from '../../common'

import Feather from 'react-native-vector-icons/Feather'
import Icon from 'react-native-vector-icons/MaterialIcons'

import * as gs from '../gStyles'

import MediaExampleModal from './MediaExampleModal'
import Pad from './Pad'
import TextInput from './TextInput'

type MpuReference = 'mpu_bus_rating' | 'mpu_breaker_rating'

export interface ModalMpuCalculatorProps {
  onRequestClose: () => void
  visible: boolean
}

export default React.memo<ModalMpuCalculatorProps>(function ModalMpuCalculator({
  onRequestClose,
  visible
}): React.ReactElement {
  const colorScheme = useColorScheme()
  const styles = gs.useThemedStyleSheet(themedStyles)
  const {
    mpuBusRating,
    mpuCalculatorResult,
    mpuMainBreakerRating,
    setMpuBusRating,
    setMPUMainBreakerRating
  } = useMpuCalculator()

  const [showReferenceModal, setShowReferenceModal] =
    React.useState<boolean>(false)
  const [mpuReferenceSelected, setMpuReferenceSelected] =
    React.useState<MpuReference>('mpu_breaker_rating')

  const handleAfterCloseModal = React.useCallback(() => {
    if (visible) {
      return
    }
    setMpuBusRating('')
    setMPUMainBreakerRating('')
  }, [setMPUMainBreakerRating, setMpuBusRating, visible])

  React.useEffect(() => {
    handleAfterCloseModal()
  }, [handleAfterCloseModal])

  const onPressModalPhotoReferences = React.useCallback(
    (referenceSelected: MpuReference) => (): void => {
      setShowReferenceModal(true)
      setMpuReferenceSelected(referenceSelected)
    },
    []
  )
  const onCloseMediaExampleModal = React.useCallback((): void => {
    setShowReferenceModal(false)
  }, [])

  return (
    <>
      <Modal
        animationType="fade"
        onRequestClose={onRequestClose}
        transparent
        visible={visible}
      >
        <SafeAreaView style={styles.container}>
          <View style={styles.content}>
            <View style={styles.contentBtn}>
              <TouchableOpacity
                onPress={onRequestClose}
                style={styles.btnCloseModal}
              >
                <Feather color="white" name="x" size={24} />
              </TouchableOpacity>
            </View>
            <ScrollView showsVerticalScrollIndicator={false}>
              <View style={styles.contentInput}>
                <TextInput
                  blurOnSubmit={false}
                  labelIconRight={
                    <TouchableOpacity
                      onPress={onPressModalPhotoReferences('mpu_bus_rating')}
                      style={styles.btnPhotos}
                    >
                      <Icon
                        color={colorScheme === 'dark' ? 'white' : 'black'}
                        name="open-in-new"
                        size={20}
                      />
                    </TouchableOpacity>
                  }
                  labelShowAsterisk
                  keyboardType="numeric"
                  label="MPU Bus Rating"
                  on="canvas"
                  onChangeText={setMpuBusRating}
                  placeholder="123.45"
                  returnKeyLabel="next"
                  returnKeyType="next"
                  value={mpuBusRating}
                />
              </View>
              <Pad amt={16} />
              <TextInput
                blurOnSubmit={false}
                labelIconRight={
                  <TouchableOpacity
                    onPress={onPressModalPhotoReferences('mpu_breaker_rating')}
                    style={styles.btnPhotos}
                  >
                    <Icon
                      color={colorScheme === 'dark' ? 'white' : 'black'}
                      name="open-in-new"
                      size={20}
                    />
                  </TouchableOpacity>
                }
                keyboardType="numeric"
                label="MPU Main Breaker Rating"
                labelShowAsterisk
                on="canvas"
                onChangeText={setMPUMainBreakerRating}
                placeholder="123.45"
                returnKeyLabel="next"
                returnKeyType="next"
                value={mpuMainBreakerRating}
              />
              <Pad amt={10} />
              <View style={styles.contentResult}>
                <Text style={styles.titleResult}>
                  Max # of solar panels without derate procedure or MPU
                  replacement:
                </Text>
                <Text style={styles.result}>
                  {mpuCalculatorResult?.maxPanelsAsIs || 'N/A'}
                </Text>
              </View>
              <View style={styles.contentResult}>
                <Text style={styles.titleResult}>
                  {mpuCalculatorResult?.maxPanelsAsIs
                    ? `Inverter required for ${mpuCalculatorResult.maxPanelsAsIs} panels:`
                    : `= Inverter required:`}
                </Text>
                <Text style={styles.result}>
                  {mpuCalculatorResult?.inverterAsIs?.name || 'N/A'}
                </Text>
              </View>
              <View style={styles.contentResult}>
                <Text style={styles.titleResult}>
                  Max # of solar panels with a derate procedure:
                </Text>
                <Text style={styles.result}>
                  {mpuCalculatorResult?.maxPanelsWithDerate ||
                    'Derate procedure not possible'}
                </Text>
              </View>
              <View style={styles.contentResult}>
                <Text style={styles.titleResult}>
                  {mpuCalculatorResult?.inverterWithDerate
                    ? `Inverter required for ${mpuCalculatorResult.maxPanelsWithDerate} panels:`
                    : `Inverter required:`}
                </Text>
                <Text style={styles.result}>
                  {mpuCalculatorResult?.inverterWithDerate?.name || 'N/A'}
                </Text>
              </View>
              <View style={styles.contentResult}>
                <Text style={styles.subtitle}>
                  MPU Upgrade is needed if derate not possible
                </Text>
                <Text style={styles.subtitle}>
                  Max MPU Rating: {MAX_MPU_RATING}
                </Text>
                <Text style={styles.subtitle}>
                  For installations of 43 panels and upwards contact Pio
                </Text>
              </View>
            </ScrollView>
          </View>
        </SafeAreaView>
        <MediaExampleModal
          mediaKind={mpuReferenceSelected}
          onRequestClose={onCloseMediaExampleModal}
          title={
            mpuReferenceSelected === 'mpu_breaker_rating'
              ? 'MPU Main Breaker Rating'
              : 'MPU Bus Rating'
          }
          visible={showReferenceModal}
        />
      </Modal>
    </>
  )
})

const themedStyles = gs.ThemedStyleSheet.create((t) => ({
  btnCloseModal: {
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.4)',
    borderRadius: 10,
    justifyContent: 'center',
    padding: 5
  },
  btnPhotos: { marginLeft: 10 },
  container: {
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.4)',
    flex: 1,
    justifyContent: 'center'
  },
  contentBtn: {
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: 10
  },
  content: {
    borderRadius: t.borderRadius,
    marginTop: '10%',
    maxHeight: '80%',
    padding: 16,
    width: '80%',
    backgroundColor: t.canvas.backgroundColor
  },
  contentInput: {},
  contentResult: { paddingVertical: 5 },
  result: {
    color: t.canvas.color,
    fontFamily: t.fontFamily,
    fontSize: 14,
    marginTop: 5,
    textAlign: 'center'
  },
  subtitle: {
    color: c.light.third,
    fontFamily: 'DMSans-Medium',
    fontSize: 13,
    marginTop: 5,
    textAlign: 'left'
  },
  titleResult: { color: t.canvas.color, fontFamily: t.fontFamily }
}))
