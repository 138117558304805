import React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'

import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import * as gs from '../gStyles'

import Select from './Picker'
import NumberInput from './NumberInput'
import Text from './Text'
import TextInput from './TextInput'

import View from './View'

export interface RowInputProps<K extends c.FieldRowable> {
  readonly customerID: string
  readonly on: c.Surface
  readonly field: K
}

export default React.memo(function ConnRowInput<K extends c.FieldRowable>({
  customerID,
  on,
  field
}: RowInputProps<K>) {
  const colorScheme = rn.useColorScheme() || 'light'
  const styles = gs.useThemedStyleSheet(themedStyles, on, colorScheme)
  const rowGap = gs.useRowGap(on)

  //#region global
  const [customer] = r.useCustomer(customerID)
  //#endregion global
  const { addRow, changeVal, cols, deleteRow, rows } = r.useRowable<K>(
    customerID,
    field
  )

  const fieldLabel = React.useMemo(() => c.getFieldLabel(field), [field])

  const cellStyles = React.useMemo(() => {
    let sum = 0

    const styles = cols.map((c) => {
      sum += c.widthPer
      return {
        // ...gStyles.deadCenter,
        // backgroundColor: rndColor(),
        marginRight: '4%',
        width: `${c.widthPer - 4}%`
      }
    })

    if (sum !== 90) {
      console.warn(
        `Column widths must add up to 90 (percent), got: ${cols
          .map((c) => c.widthPer)
          .toString()}`
      )
    }

    return styles
  }, [cols])

  const rowMapper = React.useCallback(
    (
      row: c.Rowable,
      _: number,
      rowsRef: readonly unknown[]
    ): React.ReactElement => (
      <React.Fragment key={row.id}>
        <Row
          cellStyles={cellStyles}
          cols={cols}
          on={on}
          onChangeValue={changeVal}
          onDeleteRow={deleteRow}
          row={row}
          showDelete={rowsRef.length > 1}
        />

        {rowGap}
      </React.Fragment>
    ),
    [cellStyles, cols, on, changeVal, deleteRow, rowGap]
  )

  const headerStyles = React.useMemo(
    () =>
      cols.map(
        (c): rn.ViewStyle => ({
          // backgroundColor: rndColor(),
          width: `${c.widthPer}%`
        })
      ),
    [cols]
  )

  return (
    <rn.View
      style={
        c.shouldHideInput(field, customer) ? styles.rootHidden : styles.root
      }
    >
      {fieldLabel !== field && <Text style={styles.label}>{fieldLabel}</Text>}

      {fieldLabel !== field && rowGap}

      <View marginLeft="-2%" style={gs.row}>
        {cols.map((col, colIdx) => (
          <rn.View key={customerID + col.key} style={headerStyles[colIdx]}>
            <Text centerText size={14}>
              {col.label}
            </Text>
          </rn.View>
        ))}

        {/* Delete column */}
        <rn.View style={gs.width10} />
      </View>

      {rowGap}

      {rows.map(rowMapper)}

      {rowGap}

      <rn.Pressable onPress={addRow}>
        <rn.View style={styles.buttonAdd}>
          <Icon
            color={c.themeTuple[colorScheme][on].color as string}
            name="plus"
            size={20}
          />
        </rn.View>
      </rn.Pressable>
    </rn.View>
  )
})

interface RowProps {
  readonly cellStyles: readonly rn.ViewStyle[]
  readonly cols: c.Cols
  readonly onChangeValue: (
    col: string,
    rowID: string,
    val: React.SetStateAction<number | string>
  ) => void
  readonly on: c.Surface
  readonly onDeleteRow: (rowID: string) => void
  readonly row: c.MiniSplit | c.SlidingGlassDoorGroup | c.WindowGroup
  readonly showDelete: boolean
}

const Row = React.memo<RowProps>(function Row({
  cellStyles,
  cols,
  on,
  row,
  onChangeValue,
  onDeleteRow,
  showDelete
}) {
  const t = r.useTheme()
  const styles = gs.useThemedStyleSheet(themedStyles, on)

  const handleChangeValue = React.useMemo(
    () =>
      cols.map((col) => (value: React.SetStateAction<number | string>) => {
        onChangeValue(col.key, row.id, value)
      }),
    [cols, onChangeValue, row.id]
  )

  // const handleInputClean = React.useMemo(
  //   () =>
  //     cols.map((col) => () => {
  //       onChangeValue(col.key, rowIdx, '')
  //     }),
  //   [cols, onChangeValue, rowIdx],
  // )

  const handleDeleteRow = React.useCallback(
    (): void => void onDeleteRow(row.id),
    [onDeleteRow, row.id]
  )

  if (cols.length === 0) {
    return null
  }

  return (
    <rn.View style={gs.row}>
      {cols.map((col, colIdx) => (
        <rn.View key={row.id + '>' + col.key} style={cellStyles[colIdx]}>
          {col.type === 'calc' && (
            <rn.View style={gs.deadCenterGrow}>
              <Text style={styles.calc}>{col.calc(row)}</Text>
            </rn.View>
          )}
          {col.type === 'input' && (
            <TextInput
              keyboardType="decimal-pad"
              maxLength={2}
              on={on}
              onChangeText={handleChangeValue[colIdx]}
              value={
                c.zeroes.includes(row[col.key as keyof typeof row])
                  ? ''
                  : String(row[col.key as keyof typeof row])
              }
            />
          )}
          {col.type === 'number-input' && (
            <NumberInput
              on={on}
              onChange={handleChangeValue[colIdx]}
              value={row[col.key as keyof typeof row]}
            />
          )}
          {col.type === 'opts' && (
            <Select
              on={on}
              onChange={handleChangeValue[colIdx]!}
              opts={col.opts}
              value={row[col.key as keyof typeof row]}
            />
          )}
        </rn.View>
      ))}

      <rn.View style={showDelete ? styles.rowRemove : styles.rowRemoveHidden}>
        <rn.TouchableOpacity onPress={showDelete ? handleDeleteRow : c.emptyFn}>
          <Icon color={t.danger} name="close" size={16} />
        </rn.TouchableOpacity>
      </rn.View>
    </rn.View>
  )
})

const themedStyles = gs.ThemedStyleSheet.create((t, on, colorScheme) => {
  const flippedColorScheme = colorScheme === 'dark' ? 'light' : 'dark'

  return {
    buttonAdd: {
      alignItems: 'center',
      alignSelf: 'center',
      backgroundColor: c.themeTuple[flippedColorScheme][on].color,
      borderRadius: Number.MAX_SAFE_INTEGER,
      justifyContent: 'center',
      padding: 12
    },
    calc: {
      color: t.input[on].color,
      fontFamily: t.input[on].fontFamily,
      fontSize: t.input[on].fontSize,
      fontWeight: t.input[on].fontWeight,
      textAlign: 'center',
      textAlignVertical: 'center'
    },
    label: {
      color: t[on].color,
      fontFamily: c.light.fontFamily,
      fontSize: 18
    },
    root: { gap: t[on].gap, ...gs.inputGrowNew },
    rootHidden: { gap: t[on].gap, ...gs.inputGrowNewHidden },
    rowRemove: { ...gs.deadCenter, width: '6%' },
    rowRemoveHidden: { ...gs.deadCenter, ...gs.opacity0, width: '6%' },
    textGray: { color: 'rgba(0,0,0,0.4)' },
    textGrayDark: { color: 'rgba(255,255,255,0.4)' }
  }
})
