import React from 'react'
import * as rn from 'react-native'
import { Image, TouchableHighlight, View } from 'react-native'

import * as c from '../../common'

import * as gs from '../gStyles'

// TODO: Move this here
import tick from '../screens/Customer/img/tick.png'

import Label from './Label'

export interface CheckboxProps {
  centerText?: boolean
  checked: boolean
  disabled?: boolean
  label?: string
  on: c.Surface
  onPress?: () => void
}

export default React.memo<CheckboxProps>(function Checkbox({
  centerText,
  checked,
  disabled,
  label,
  on,
  onPress
}): React.ReactElement {
  const styles = gs.useThemedStyleSheet(themedStyles)

  return (
    <TouchableHighlight
      style={styles.button}
      onPress={onPress}
      underlayColor="transparent"
    >
      <View
        style={centerText ? styles.containerCenteredText : styles.container}
      >
        {label && (
          <rn.View style={styles.marginLeft32}>
            <Label disabled={disabled} on={on} onPress={onPress}>
              {label}
            </Label>
          </rn.View>
        )}

        <View
          style={
            centerText
              ? styles.checkboxCenteredText
              : disabled
              ? styles.checkboxDisabled
              : styles.checkbox
          }
        >
          {checked ? (
            <View style={styles.checked}>
              <Image
                style={styles.checkedImageStyle as rn.ImageStyle}
                source={tick as rn.ImageSourcePropType}
              />
            </View>
          ) : (
            <View style={styles.unchecked} />
          )}
        </View>
      </View>
    </TouchableHighlight>
  )
})

export const CHECKBOX_EDGE = 20

const containerBase = {} as const

const themedStyles = gs.ThemedStyleSheet.create((t) => {
  const checkboxBase = {
    backgroundColor: t.canvas.highlight,
    borderRadius: 1.5,
    height: CHECKBOX_EDGE,
    padding: 4,
    width: CHECKBOX_EDGE
  } as const

  return {
    button: { alignSelf: 'center' },
    checkbox: checkboxBase,
    checkboxDisabled: { ...checkboxBase, opacity: 0.5 },
    checkboxCenteredText: { ...checkboxBase, left: 0, position: 'absolute' },
    checked: { alignItems: 'center', flex: 1, justifyContent: 'center' },
    checkedImageStyle: {
      height: '85%',
      resizeMode: 'contain',
      tintColor: '#FFF',
      width: '85%'
    },
    container: { ...containerBase, alignItems: 'center', flexDirection: 'row' },
    containerCenteredText: containerBase,
    marginLeft32: { marginLeft: 32 },
    unchecked: { backgroundColor: t.canvas.backgroundColor, flex: 1 }
  }
})
