import { createSlice } from '@reduxjs/toolkit'

import * as utils from '../utils'
import {
  efficiencyKeys,
  efficiencyToInputs,
  fieldToEfficiency
} from '../helpers'

import { updatedCustomerActionCreator } from './common'
import {
  receivedCustomersFirebaseEvents,
  receivedSingleCustomerFirebaseEvents
} from './customers'

type SaleStatus = {
  isRefreshing: boolean
  lastCost: number
}

type CustomerSaleStatuses = utils.DeepReadonly<
  Record<keyof typeof efficiencyToInputs, SaleStatus>
>

type CostsState = utils.DeepReadonly<
  Partial<Record<string, CustomerSaleStatuses>>
>

const initialState: CostsState = {}

const PREFIX = 'costs'

const initCustomer = (): CustomerSaleStatuses => {
  const fieldValue = {} as utils.Writable<CustomerSaleStatuses>

  for (const field of efficiencyKeys) {
    fieldValue[field] = {
      isRefreshing: false,
      lastCost: 0
    }
  }

  return fieldValue
}

export const { reducer: costs } = createSlice({
  initialState,
  name: PREFIX,
  reducers: {},
  extraReducers(builder) {
    return builder
      .addCase(
        updatedCustomerActionCreator,
        (costs, { payload: { customerID, data } }) => {
          // console.log(`updatedCustomer(${customerID})`, {
          //   customerID,
          //   data,
          // })
          if (!costs[customerID]) costs[customerID] = initCustomer()

          const updatedFields = utils.keys(data)

          for (const field of updatedFields) {
            const saleField = fieldToEfficiency(field)
            /**
             * Disable for now because not all sale fields automatically trigger
             * a cost refresh until sub-fields are filled.
             */
            // || utils.keys(saleToInputs).find((_) => _ === field)
            // console.log(`saleField(${field}) === ${saleField}`)
            if (!saleField) continue

            // console.log(
            //   `Setting ${saleField} to refreshing, was: ${
            //     costs[customerID]![saleField].isRefreshing
            //   }`,
            // )
            // initCustomer() is called above
            costs[customerID]![saleField].isRefreshing = true
          }
        }
      )
      .addCase(
        receivedCustomersFirebaseEvents,
        (costs, { payload: { events } }) => {
          for (const { item: customerData, key: customerID, type } of events) {
            if (type === 'child_removed') {
              delete costs[customerID]
              continue
            }

            if (type !== 'child_added' && type !== 'child_changed') continue

            if (!costs[customerID]) costs[customerID] = initCustomer()

            const costFields = utils.keys(customerData).filter(
              (field) =>
                field.endsWith('_cost') &&
                !field.endsWith('customer_cost') &&
                !field.endsWith('touched_cost') &&
                !field.startsWith('custom_') &&
                // TODO: How to better deactivate fields
                !field.startsWith('new_ducts')
            )

            for (const costField of costFields) {
              let saleField = costField.replace(
                '_cost',
                ''
              ) as keyof typeof efficiencyToInputs

              if (!(saleField in efficiencyToInputs)) {
                throw new ReferenceError(
                  `costField.replace('_cost', '') not a sale field, costField: ${costField}`
                )
              }

              const newCost = Number(customerData[costField])

              if (!Number.isFinite(newCost)) {
                // utils.log(
                //   `newCost is not a finite number, ${customerData.key}[${costField}]: ${customerData[costField]}`,
                // )
                continue
              }

              // initCustomer() is called above
              const saleStatus = costs[customerID]![saleField]
              const { lastCost } = saleStatus

              const didNotRefresh = lastCost !== 0 && lastCost !== newCost

              if (didNotRefresh) {
                // console.log(`${saleField} dit not refresh`, {
                //   lastCost,
                //   newCost,
                // })
              } else {
                // console.log(`${saleField} refreshed`, {
                //   lastCost,
                //   newCost,
                // })
                saleStatus.isRefreshing = false
              }

              saleStatus.lastCost = newCost
            }
          }
        }
      )
      .addCase(
        receivedSingleCustomerFirebaseEvents,
        (costs, { payload: { customerID, updates } }) => {
          if (!costs[customerID]) costs[customerID] = initCustomer()

          const costFieldUpdates = updates.filter(
            ({ field }) =>
              field.endsWith('_cost') &&
              !field.endsWith('customer_cost') &&
              !field.startsWith('custom_') &&
              // TODO: How to better deactivate fields
              !field.startsWith('new_ducts')
          )

          if (costFieldUpdates.length === 0) return

          for (const {
            field: updatedCostField,
            value: newCostUnparsed
          } of costFieldUpdates) {
            const newCost = utils.parseFloating(newCostUnparsed)
            if (!Number.isFinite(newCost)) {
              // utils.log(
              //   `newCost is not a finite number, newCostUnparsed: ${newCostUnparsed}, field: ${updatedCostField}`,
              // )
              continue
            }

            let saleField = updatedCostField.replace(
              '_cost',
              ''
            ) as keyof typeof efficiencyToInputs

            if (!(saleField in efficiencyToInputs)) {
              throw new ReferenceError(
                `updatedCostField.replace('_cost', '') not a sale field, updatedCostField: ${updatedCostField}`
              )
            }

            // initCustomer() is called above
            const saleStatus = costs[customerID]![saleField]
            const { lastCost } = saleStatus

            const didNotRefresh = lastCost !== 0 && lastCost !== newCost

            if (didNotRefresh) {
              // console.log(`${saleField} dit not refresh`, {
              //   lastCost,
              //   newCost,
              // })
            } else {
              // console.log(`${saleField} refreshed`, {
              //   lastCost,
              //   newCost,
              // })
              saleStatus.isRefreshing = false
            }

            saleStatus.lastCost = newCost
          }
        }
      )
  }
})

//#region selectors
interface GlobalState {
  [PREFIX]: CostsState
}

// TODO: is createSelector() necessary?
export const selectIsRefreshingCost =
  (customerID: string, costField: keyof typeof efficiencyToInputs) =>
  ({ costs }: GlobalState): boolean => {
    if (!costField.endsWith('_cost')) {
      return false
    }

    // Temporarily disable functionality
    return !!customerID && !!costs && false
  }
//#endregion selectors
