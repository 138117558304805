import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

export interface PadProps {
  amt: ViewStyle['width']
  color?: string
  horizontal?: boolean
  row?: boolean
}

export default React.memo<PadProps>(function Pad({
  amt,
  color,
  horizontal,
  row
}): React.ReactElement {
  const style = React.useMemo(
    (): StyleProp<ViewStyle> =>
      horizontal || row
        ? {
            backgroundColor: color,
            width: amt
          }
        : {
            backgroundColor: color,
            height: amt
          },
    [amt, color, horizontal, row]
  )

  return <View style={style} />
})
