import React from 'react'
import * as rn from 'react-native'

import * as c from '../common'
import * as r from '../react-utils'

export const flex = { display: 'flex' } as const
export const absolute = { position: 'absolute' } as const

export const alignItemsCenter = { ...flex, alignItems: 'center' } as const
export const alignItemsEnd = { ...flex, alignItems: 'flex-end' } as const

export const alignSelfCenter = { alignSelf: 'center' } as const

export const deadCenter = {
  alignItems: 'center',
  // backgroundColor: 'teal',
  justifyContent: 'center'
} as const

export const disabled = { opacity: 0.56 } as const

export const displayNone = { display: 'none' } as const

export const justifyBetween = {
  ...flex,
  justifyContent: 'space-between'
} as const

export const justifyCenter = { ...flex, justifyContent: 'center' } as const

export const flexGrow = { ...flex, flexGrow: 1 } as const

/**
 * TODO: Use universal shadow library.
 */
export const shadowThird = {
  elevation: 8,
  shadowColor: '#000',
  shadowOffset: { height: 0, width: 0 },
  shadowOpacity: 0.08,
  shadowRadius: 8
}

/**
 * TODO: Use universal shadow library.
 */
export const shadowHalf = {
  elevation: 12,
  shadowColor: '#000',
  shadowOffset: { height: 8, width: 0 },
  shadowOpacity: 0.2,
  shadowRadius: 9.6
}

/**
 * TODO: Use universal shadow library.
 */
export const shadowFull = {
  elevation: 24,
  shadowColor: '#000',
  shadowOffset: { height: 16, width: 0 },
  shadowOpacity: 0.24,
  shadowRadius: 20.0
}

export const frostedBase = {
  ...shadowHalf,
  borderRadius: 16
} as const

const frostedAlfa = rn.Platform.OS === 'android' ? 1 : 0.8

export const frostedLight = {
  ...frostedBase,
  // TODO: Use universal shadow library, then set this back to 0.8 alpha always
  backgroundColor: `rgba(238, 238, 241, ${frostedAlfa})`
} as const

export const frostedDark = {
  ...frostedBase,
  // TODO: Use universal shadow library, then set this back to 0.8 alpha always
  backgroundColor: `rgba(28, 28, 28, ${frostedAlfa})`
} as const

export const frostedBorderRad = 16

export const grow = { ...flex, flexGrow: 1 } as const

export const inputGrow = {
  minWidth: rn.Platform.OS === 'web' ? c.fieldWidthWeb : undefined
  // width: '100%',
}
export const inputShrink = {
  // marginHorizontal: r.isMobile ? t[on].paddingHorizontal : undefined,
  maxWidth: c.fieldWidthWeb,
  width: '100%'
}

export const inputShrinkNew = {
  flexGrow: 1,
  flexBasis: 0.3,
  minWidth: 240
} as const
export const inputGrowNew = { width: '100%' } as const
export const inputShrinkNewHidden = {
  flexGrow: 1,
  flexBasis: 0.3,
  minWidth: 240,
  opacity: 0
} as const
export const inputGrowNewHidden = {
  display: 'none',
  width: '100%'
} as const

export const opacity0 = { opacity: 0 } as const
export const opacity1 = { opacity: 1 } as const

export const padH1 = { paddingHorizontal: 1 }
export const padH2 = { paddingHorizontal: 2 }
export const padH4 = { paddingHorizontal: 4 }
export const padH6 = { paddingHorizontal: 6 }
export const padH8 = { paddingHorizontal: 8 }
export const padH12 = { paddingHorizontal: 12 }
export const padH16 = { paddingHorizontal: 16 }
export const padH24 = { paddingHorizontal: 24 }
export const padH128 = { paddingHorizontal: 128 }

export const padV1 = { paddingVertical: 1 }
export const padV2 = { paddingVertical: 2 }
export const padV4 = { paddingVertical: 4 }
export const padV6 = { paddingVertical: 6 }
export const padV8 = { paddingVertical: 8 }
export const padV12 = { paddingVertical: 12 }
export const padV14 = { paddingVertical: 14 }
export const padV16 = { paddingVertical: 16 }
export const padV24 = { paddingVertical: 24 }
export const padV28 = { paddingVertical: 28 }
export const padV32 = { paddingVertical: 32 }
export const padV128 = { paddingVertical: 128 }

export const shrink = { ...flex, flexShrink: 1 } as const

export const colCentered = {
  ...alignItemsCenter,
  flexDirection: 'column'
} as const

export const row = { flexDirection: 'row' } as const

export const rowCentered = { ...alignItemsCenter, ...row } as const

export const rowCenteredGrow = {
  ...alignItemsCenter,
  ...row,
  ...grow
} as const

export const rowCenteredShrink = {
  ...alignItemsCenter,
  ...row,
  ...shrink
} as const

export const rowWrap = { ...row, flexWrap: 'wrap' } as const

export const txtAlignRight = { textAlign: 'right' } as const

export const whiteBG = { backgroundColor: 'white' } as const

export const width10 = { width: '10%' }
export const width24 = { width: '24%' }
export const width36 = { width: '36%' }
export const width40 = { width: '40%' }
export const width48 = { width: '48%' }
export const width50 = { width: '50%' }
export const width56 = { width: '56%' }
export const width100 = { width: '100%' }

const _userSelectNone = {
  '-webkit-user-select': 'none',
  userSelect: 'none'
} as unknown as rn.TextStyle
export const userSelectNone = r.isWeb
  ? _userSelectNone
  : (c.emptyObj as rn.TextStyle)

export const zIndex1 = { zIndex: 1 }

//#region composite
export const deadCenterGrow = { ...deadCenter, ...grow }
export const rowSpaceBetween = { ...row, ...justifyBetween }
export const rowSpaceBetween100 = { ...row, ...justifyBetween, ...width100 }
export const rowSpaceBetweenWrap = { ...rowWrap, ...rowSpaceBetween }
export const rowSpaceBetweenWrapShrink = {
  ...rowWrap,
  ...rowSpaceBetween,
  ...shrink
}
//#endregion composite

//#region theming

type ThemedStyle = (rn.ImageStyle | rn.TextStyle | rn.ViewStyle) & c.Layer

export type NamedThemedStylesMap<T> = Readonly<Record<keyof T, ThemedStyle>>

export interface IThemedStyleSheet<T> {
  readonly backdrop: {
    readonly dark: NamedThemedStylesMap<T>
    readonly light: NamedThemedStylesMap<T>
  }
  readonly canvas: {
    readonly dark: NamedThemedStylesMap<T>
    readonly light: NamedThemedStylesMap<T>
  }
  readonly paper: {
    readonly dark: NamedThemedStylesMap<T>
    readonly light: NamedThemedStylesMap<T>
  }
}

type ThemedStyleSheetCreator<T extends NamedThemedStylesMap<unknown>> = (
  t: c.Theme,
  on: c.Surface,
  colorScheme: 'dark' | 'light'
) => NamedThemedStylesMap<T>

export const ThemedStyleSheet = {
  create: <T extends NamedThemedStylesMap<unknown>>(
    themeCreator: ThemedStyleSheetCreator<T>
  ): IThemedStyleSheet<T> => {
    return {
      backdrop: {
        dark: themeCreator(c.themeTuple.dark, 'backdrop', 'dark'),
        light: themeCreator(c.themeTuple.light, 'backdrop', 'light')
      },
      canvas: {
        dark: themeCreator(c.themeTuple.dark, 'canvas', 'dark'),
        light: themeCreator(c.themeTuple.light, 'canvas', 'light')
      },
      paper: {
        dark: themeCreator(c.themeTuple.dark, 'paper', 'dark'),
        light: themeCreator(c.themeTuple.light, 'paper', 'light')
      }
    }
  }
}

export const useThemedStyleSheet = <T extends NamedThemedStylesMap<unknown>>(
  themedStyles: IThemedStyleSheet<T>,
  on: c.Surface = 'backdrop',
  colorScheme?: c.ColorScheme
): rn.StyleSheet.NamedStyles<T> => {
  const colorSchemeRN = rn.useColorScheme() || 'light'

  return themedStyles[on][colorScheme || colorSchemeRN]
}

export const themed = ThemedStyleSheet.create((t, on) => {
  const txt: rn.TextStyle = { color: t[on].color, fontFamily: t.fontFamily }
  const txtWrap: rn.TextStyle = { ...txt, flexWrap: 'wrap' }

  const canvasGap = (
    r.isWeb ? (t.canvas.gap || 0) * 2 : t.canvas.gap || 0
  ) as c.Gap

  const gap = (r.isWeb ? (t[on].gap || 0) * 2 : t[on].gap) as c.Gap

  const contentBase = {
    alignItems: 'flex-end',
    // flexDirection: r.isWeb ? 'row' : 'column',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap
    // flexGrow: 1,
  } as const

  return {
    absolute,
    alignItemsCenter,
    alignItemsEnd,
    deadCenter,
    displayNone,
    flexGrow,
    justifyBetween,
    justifyCenter,
    inputShrinkNew,
    inputGrowNew,
    inputShrinkNewHidden,
    inputGrowNewHidden,
    shadowHalf,
    shadowFull,
    frostedBase,
    frostedLight,
    frostedDark,
    rowSpaceBetween,
    opacity0,
    padH1,
    padH2,
    padH4,
    padH6,
    padH8,
    padH12,
    padH16,
    padH128,
    padV1,
    padV2,
    padV4,
    padV6,
    padV8,
    padV12,
    padV16,
    padV128,
    row,
    rowCentered,
    rowSpaceBetween100,
    shrink,
    whiteBG,
    width10,
    width36,
    width40,
    width48,
    width50,
    width56,
    width100,
    zIndex1,
    // Themed
    backdrop: { backgroundColor: t.backdrop.backgroundColor },
    backdropGrow: { backgroundColor: t.backdrop.backgroundColor, flexGrow: 1 },
    canvas: { ...t.canvas, gap: undefined },
    canvasGrow: { backgroundColor: t.canvas.backgroundColor, flexGrow: 1 },
    cardContent: { ...t.paper, ...rowWrap, ...justifyBetween },
    content: { ...contentBase, margin: gap },
    contentNoMargin: { ...contentBase, width: '100%' },
    contentHorizontal: {
      flexDirection: 'row',
      flexGrow: 1,
      flexShrink: 1,
      flexWrap: 'wrap',
      gap: t[on].gap
    },
    paper: { ...t.paper, margin: canvasGap, gap: undefined },
    separatorH: { backgroundColor: t[on].separator, height: 1, width: '100%' },
    separatorV: { backgroundColor: t[on].separator, height: '100%', width: 1 },
    txt,
    txtDanger: { ...txt, color: t.danger },
    txtWrap,
    txtWrap12: { ...txt, ...shrink, flexWrap: 'wrap', fontSize: 12 }
  } as const
})

export const useGlobalStyles = (on?: c.Surface) =>
  useThemedStyleSheet(themed, on)

export const rowGap2 = <rn.View style={{ ...padV1, width: '100%' }} />
export const rowGap4 = <rn.View style={{ ...padV2, width: '100%' }} />
export const rowGap8 = <rn.View style={{ ...padV4, width: '100%' }} />
export const rowGap12 = <rn.View style={{ ...padV6, width: '100%' }} />
export const rowGap16 = <rn.View style={{ ...padV8, width: '100%' }} />
export const rowGap24 = <rn.View style={{ ...padV12, width: '100%' }} />
export const rowGap28 = <rn.View style={{ ...padV14, width: '100%' }} />
export const rowGap32 = <rn.View style={{ ...padV16, width: '100%' }} />
export const rowGap64 = <rn.View style={{ ...padV32, width: '100%' }} />
export const rowGap256 = <rn.View style={{ ...padV128, width: '100%' }} />

/**
 * Stop-gap awaiting RN upgrade that supports flex-gap.
 */
export const useRowGap = (on?: c.Surface): React.ReactElement | null => {
  const t = r.useTheme()

  const { gap } = t[on || 'backdrop']

  if (rn.Platform.OS === 'web') return null // web uses flex-gap

  if (gap === 2) return rowGap2
  if (gap === 4) return rowGap4
  if (gap === 8) return rowGap8
  if (gap === 12) return rowGap12
  if (gap === 16) return rowGap16
  if (gap === 24) return rowGap24
  if (gap === 28) return rowGap28
  if (gap === 32) return rowGap32

  return null
}

export const colGap8 = <rn.View style={padH4} />
export const ColGap8 = React.memo(() => colGap8)
export const colGap24 = <rn.View style={padH12} />
export const ColGap24 = React.memo(() => colGap24)
//#endregion theming
