import React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'

import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

import * as gs from '../gStyles'

import Labeled from './Labeled'
import Text from './Text'
import View from './View'

export interface IPicker {
  readonly close: () => void
  readonly open: () => void
}

export type PickerProps = c.r<{
  childRef?: React.RefObject<rn.TextInput | null | undefined>
  children?: React.ReactElement
  disabled?: boolean
  grow?: boolean
  guideline?: string
  guidelineType?: c.Guideline['type']
  hide?: boolean
  inline?: boolean
  label?: false | string
  labelShowAsterisk?: boolean
  noModal?: boolean
  on: c.Surface
  onChange?: (value: string) => void
  onRequestClose?: c.VoidFn
  open?: boolean
  openToTheLeft?: boolean
  opts: c.Opts
  required?: boolean
  value?: string
}>

const initDropdownGeometry = Object.freeze({
  top: -1,
  left: -1,
  maxHeight: -1,
  width: -1
})

const PickerForwarded = React.forwardRef<IPicker | undefined, PickerProps>(
  function Picker(
    {
      childRef,
      children,
      disabled,
      grow,
      guideline: guidelineProp,
      guidelineType: guidelineTypeProp,
      hide,
      inline,
      label,
      labelShowAsterisk,
      noModal,
      on,
      onChange,
      onRequestClose,
      open: openProp,
      openToTheLeft,
      opts,
      required,
      value: selectedValue
    },
    ref
  ): React.ReactElement | null {
    const colorScheme = rn.useColorScheme()
    const isDark = colorScheme === 'dark'
    const styles = gs.useThemedStyleSheet(themedStyles, on)

    const [isVisible, setIsVisible, toggleIsVisible] = r.useBool(false)

    const [dropdownGeometry, setDropdownGeometry] = React.useState<{
      bottom?: number
      maxHeight?: number
      left: number
      top?: number
      width: number
    }>(initDropdownGeometry)

    //#region refs
    const buttonRef = React.useRef<rn.TouchableOpacity | null>(null)
    const instance = React.useMemo(
      () => ({
        close() {
          setIsVisible(false)
        },
        open() {
          setIsVisible(true)
        }
      }),
      [setIsVisible]
    )
    React.useImperativeHandle(ref, () => instance)
    //#endregion refs

    //#region local
    const selectedNotInOpts = React.useMemo(
      () => !!selectedValue && !opts.find((o) => o.value === selectedValue),
      [opts, selectedValue]
    )
    const guideline = (() => {
      if (guidelineProp) return !(isVisible || openProp) && guidelineProp
      if (required && !selectedValue) return 'Required'
      return undefined
    })()
    const guidelineType = (() => {
      if (guidelineTypeProp) return guidelineTypeProp
      if (required && !selectedValue) return 'warning'
      return undefined
    })()
    //#endregion local
    const calcGeometry = React.useCallback((): void => {
      ;(childRef || buttonRef).current?.measure(
        (_, __, _width, height, pageX, pageY) => {
          const isZoomedOut = !(r.isSafari() || r.isMobile)

          let screenHeight = rn.Dimensions.get('window').height

          const spaceBelow = screenHeight - pageY - height

          const maxHeight = rn.Dimensions.get('window').height * 0.4

          const width = children ? c.fieldWidthWeb : _width

          const _left = isZoomedOut ? pageX / 0.72 : pageX
          const left = openToTheLeft ? _left - width : _left

          // Determines whether the dropdown menu should scroll up or down
          if (spaceBelow >= maxHeight) {
            const pageYFinal = isZoomedOut ? pageY / 0.72 : pageY
            // Drop down
            setDropdownGeometry({
              left,
              maxHeight,
              top: pageYFinal + height,
              width
            })
          } else {
            // Unfold up
            setDropdownGeometry({
              bottom: isZoomedOut
                ? (screenHeight - pageY) / 0.72
                : screenHeight - pageY,
              left,
              maxHeight,
              width
            })
          }
        }
      )
    }, [childRef, children, openToTheLeft])

    React.useEffect(() => {
      if (isVisible || openProp) {
        calcGeometry()

        if (r.isWeb) {
          // @ts-ignore
          document.body.style.overflow = 'hidden'
        }
      }

      return () => {
        setDropdownGeometry(initDropdownGeometry)
        if (r.isWeb) {
          // @ts-ignore
          document.body.style.overflow = 'unset'
        }
      }
    }, [calcGeometry, children, isVisible, openProp])

    const handleSelect = React.useCallback(
      (value: string) => {
        onChange?.(value)
        setIsVisible(false)
      },
      [onChange, setIsVisible]
    )

    const renderItem = React.useCallback(
      ({ item }: { item: c.Opt }): React.ReactElement => (
        <CDropdownItem
          isSelected={selectedValue === item.value}
          label={item.label}
          onSelect={handleSelect}
          value={item.value}
        />
      ),
      [handleSelect, selectedValue]
    )

    const dropdownStyle = React.useMemo(
      () => [
        gs.absolute,
        styles.dropdown,
        dropdownGeometry,
        noModal && gs.zIndex1 // TODO: revisit.
      ],
      [dropdownGeometry, noModal, styles.dropdown]
    )

    const closeModal = React.useCallback((): void => {
      setIsVisible(false)
    }, [setIsVisible])

    const initialScrollIndex =
      opts.findIndex((x) => x.value === selectedValue) || 0

    const renderDropdown = React.useCallback((): React.ReactElement | null => {
      const flatListNode = (
        <rn.FlatList
          // contentContainerStyle={{ height: 200 }}
          ItemSeparatorComponent={CustomDropdownSeparator}
          data={opts}
          initialScrollIndex={initialScrollIndex}
          keyExtractor={keyExtractor}
          onScrollToIndexFailed={console.log}
          renderItem={renderItem}
          style={dropdownStyle}
        />
      )

      if (noModal) return openProp || isVisible ? flatListNode : <></>

      if (c.values(dropdownGeometry).every((x) => x === -1)) return null

      return (
        <rn.Modal
          animationType="none"
          onRequestClose={onRequestClose || toggleIsVisible}
          transparent
          visible={openProp || isVisible}
        >
          <rn.TouchableOpacity
            onPress={onRequestClose || closeModal}
            style={styles.backdrop}
          >
            {flatListNode}
          </rn.TouchableOpacity>
        </rn.Modal>
      )
    }, [
      opts,
      initialScrollIndex,
      renderItem,
      dropdownStyle,
      noModal,
      openProp,
      isVisible,
      dropdownGeometry,
      onRequestClose,
      toggleIsVisible,
      closeModal,
      styles.backdrop
    ])

    const buttonTextStyle = React.useMemo(
      () => [styles.btnTxt, !selectedValue && styles.placeholder],
      [selectedValue, styles.btnTxt, styles.placeholder]
    )

    if (disabled || hide || selectedNotInOpts) {
      const disabledBtn = (
        <View
          style={
            !grow && !label ? styles.btnDisabledShrink : styles.btnDisabled
          }
        >
          <Text style={styles.btnDisabledTxt}>
            {opts.find((o) => o.value === selectedValue)?.label ||
              selectedValue ||
              'Select...'}
          </Text>
        </View>
      )

      return label ? (
        <Labeled
          disabled
          grow={grow}
          guideline={guideline}
          guidelineType={guidelineType}
          hide={hide}
          label={label}
          on={on}
          showAsterisk={labelShowAsterisk}
        >
          {children || disabledBtn}
        </Labeled>
      ) : (
        children || disabledBtn
      )
    }

    if (inline && !openProp) return null
    if (inline)
      return (
        <rn.ScrollView style={styles.dropdown}>
          {opts.map((o) => (
            <React.Fragment key={o.value}>
              <CDropdownItem
                isSelected={selectedValue === o.value}
                label={o.label}
                onSelect={handleSelect}
                value={o.value}
              />
              <CustomDropdownSeparator />
            </React.Fragment>
          ))}
        </rn.ScrollView>
      )

    const btnStyle = (() => {
      if (!grow && !label) {
        return isVisible || openProp
          ? styles.btnShrinkFocused
          : styles.btnShrink
      }
      return isVisible || openProp ? styles.btnFocused : styles.btn
    })()
    const btn = (
      <rn.Pressable onPress={toggleIsVisible} ref={buttonRef} style={btnStyle}>
        <Text style={buttonTextStyle}>
          {opts.find((o) => o.value === selectedValue)?.label || 'Select...'}
        </Text>

        <Icon
          color={isDark ? 'white' : 'black'}
          name={isVisible ? 'chevron-up' : 'chevron-down'}
          size={24}
          style={gs.userSelectNone}
        />
      </rn.Pressable>
    )

    return (
      <>
        {label ? (
          <Labeled
            grow={grow}
            guideline={guideline}
            guidelineType={guidelineType}
            label={label}
            on={on}
            showAsterisk={labelShowAsterisk}
          >
            {children || btn}
          </Labeled>
        ) : (
          children || btn
        )}
        {renderDropdown()}
      </>
    )
  }
)

const keyExtractor = (item: c.Opt): string => item.value

interface ItemProps extends c.Opt {
  readonly isSelected: boolean
  readonly onSelect: (value: string) => void
}

export const CDropdownItem = React.memo<ItemProps>(function DropdownItem({
  isSelected,
  label,
  onSelect,
  value
}) {
  const t = r.useTheme()
  const styles = gs.useThemedStyleSheet(themedStyles)

  const handlePress = React.useCallback(() => {
    onSelect(value)
  }, [onSelect, value])

  return (
    <rn.Pressable onPress={handlePress} style={styles.item}>
      <Text style={styles.itemText}>{label}</Text>

      {isSelected && (
        <Icon
          color={t.paper.highlight}
          name="check"
          size={24}
          style={gs.userSelectNone}
        />
      )}
    </rn.Pressable>
  )
})

const CustomDropdownSeparator = React.memo(function CustomDropdownSeparator() {
  const styles = gs.useGlobalStyles('backdrop')
  return <rn.View style={styles.separatorH} />
})

const themedStyles = gs.ThemedStyleSheet.create((t, on, _) => {
  const btn = {
    ...t.input[on],
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 15 // TODO: Doesn't match input height otherwise
  } as const
  const btnTxt = {
    fontFamily: t.input[on].fontFamily,
    color: t.input[on].color,
    fontSize: 15,
    ...gs.userSelectNone
  } as const
  const dropdown = {
    ...t.backdrop,
    borderRadius: t.borderRadius,
    ...gs.shadowHalf
  } as const

  return {
    backdrop: { ...gs.flexGrow, touchAction: 'none' },
    btn,
    btnDisabled: { ...btn, ...gs.deadCenter, opacity: 0.5 },
    btnDisabledShrink: { ...btn, ...gs.inputShrink, opacity: 0.5 },
    btnDisabledTxt: {
      color: t.input[on].color,
      fontFamily: t.input[on].fontFamily,
      fontSize: 15,
      opacity: 0.5,
      textAlign: 'center',
      textAlignVertical: 'center',
      ...gs.userSelectNone
    },
    btnFocused: { ...btn, borderColor: t[on].highlight },
    btnShrink: { ...btn, ...gs.inputShrink },
    btnShrinkFocused: {
      ...btn,
      ...gs.inputShrink,
      borderColor: t[on].highlight
    },
    btnTxt,
    btnTxtPlaceholder: {
      ...btnTxt,
      fontFamily: t.input[on].placeholderColor
    },
    dropdown,
    icon: { marginRight: 10 },
    placeholder: { color: t.input[on].placeholderColor },
    item: {
      alignItems: 'center',
      borderRadius: t.borderRadius,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 12,
      paddingVertical: 12
    },
    itemText: {
      color: t.backdrop.color,
      fontFamily: t.fontFamily,
      ...gs.userSelectNone
    }
  }
})

export default React.memo(PickerForwarded)

// function isSafariMobile() {
//   if (r.isMobile) return false;
//   return (
//     [
//       'iPad Simulator',
//       'iPhone Simulator',
//       'iPod Simulator',
//       'iPad',
//       'iPhone',
//       'iPod',
//       // @ts-ignore
//     ].includes(navigator.platform) ||
//     // iPad on iOS 13 detection
//     // @ts-ignore
//     (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
//   );
// }
