import React from 'react'
import * as rn from 'react-native'

import Ionicons from 'react-native-vector-icons/Ionicons'

import * as c from '../../common'

import * as gs from '../gStyles'

export type GuidelineProps = c.r<{
  children: false | string
  on: c.Surface
  type?: c.Guideline['type']
}>

// TODO: update with
const rootStyle: rn.ViewStyle = {
  alignItems: 'center',
  position: 'absolute',
  left: 0,
  bottom: -12,
  width: '100%'
}

let rootStyleCalc: rn.StyleProp<rn.ViewStyle> = null

const handleLayout = ({
  nativeEvent: {
    layout: { height }
  }
}: rn.LayoutChangeEvent): void => {
  if (!rootStyleCalc) {
    rootStyleCalc = {
      ...rootStyle,
      bottom: -(height / 2)
    }
  }
}

export default React.memo<GuidelineProps>(function Guideline({
  children: text,
  on,
  type = 'info'
}) {
  //#region styling
  const styles = gs.useThemedStyleSheet(themedStyles, on)
  //#endregion styling

  const isWarning = type === 'warning'

  const icon = (
    <Ionicons
      color="white"
      name={isWarning ? 'warning-outline' : 'information-circle-outline'}
      size={20}
    />
  )

  return (
    <rn.View onLayout={handleLayout} style={rootStyleCalc || rootStyle}>
      <rn.View
        style={isWarning ? styles.containerWarning : styles.containerInfo}
      >
        <rn.View style={gs.userSelectNone}>{icon}</rn.View>

        <rn.Text style={styles.text}>{text}</rn.Text>
      </rn.View>
    </rn.View>
  )
})

const fontSize = 10
const themedStyles = gs.ThemedStyleSheet.create((t, on) => {
  const container = {
    borderRadius: Number.MAX_SAFE_INTEGER,
    paddingHorizontal: 12,
    paddingVertical: 2,
    maxWidth: '88%',
    ...gs.rowCentered
  }
  return {
    containerInfo: { ...container, backgroundColor: t[on].highlight },
    containerWarning: { ...container, backgroundColor: t.warning },
    text: {
      color: c.white,
      fontFamily: t.fontFamily,
      fontSize,
      textAlign: 'center',
      textAlignVertical: 'center',
      flexShrink: 1,
      flexWrap: 'wrap',
      ...gs.userSelectNone
    }
  }
})
