import React from 'react'
import * as rn from 'react-native'
import { Text as RNText } from 'react-native'

import * as c from '../../common'

import * as gs from '../gStyles'

export type TextProps = c.r<{
  bold?: boolean | null | undefined
  /**
   * If iOS, it will wrap
   */
  centerText?: boolean | null | undefined
  colorScheme?: c.ColorScheme
  children: any
  ellipsizeMode?: rn.TextProps['ellipsizeMode']
  grow?: boolean
  numberOfLines?: number
  on?: c.Surface
  padding?: rn.TextStyle['padding']
  paddingHorizontal?: rn.TextStyle['paddingHorizontal']
  right?: number
  size?: 8 | 10 | 12 | 14 | 16 | 18 | 20 | 22 | 24
  style?: rn.StyleProp<rn.TextStyle>
}>

export default React.memo<TextProps>(function Text({
  bold,
  centerText,
  children,
  colorScheme,
  grow,
  numberOfLines,
  on,
  size = 14,
  style
}) {
  const gStyles = gs.useThemedStyleSheet(themedStyles, on, colorScheme)

  const txtStyle = ((): rn.TextStyle => {
    if (style) return style as rn.TextStyle

    if (size === 8) {
      if (centerText)
        return bold ? gStyles.size8CenteredBold : gStyles.size8Centered
      return bold ? gStyles.size8Bold : gStyles.size8
    } else if (size === 10) {
      if (centerText)
        return bold ? gStyles.size10CenteredBold : gStyles.size10Centered
      return bold ? gStyles.size10Bold : gStyles.size10
    } else if (size === 12) {
      if (centerText)
        return bold ? gStyles.size12CenteredBold : gStyles.size12Centered
      return bold ? gStyles.size12Bold : gStyles.size12
    } else if (size === 14) {
      if (centerText)
        return bold ? gStyles.size14CenteredBold : gStyles.size14Centered
      return bold ? gStyles.size14Bold : gStyles.size14
    } else if (size === 16) {
      if (centerText)
        return bold ? gStyles.size16CenteredBold : gStyles.size16Centered
      return bold ? gStyles.size16Bold : gStyles.size16
    } else if (size === 18) {
      if (centerText)
        return bold ? gStyles.size18CenteredBold : gStyles.size18Centered
      return bold ? gStyles.size18Bold : gStyles.size18
    } else if (size === 20) {
      if (centerText)
        return bold ? gStyles.size20CenteredBold : gStyles.size20Centered
      return bold ? gStyles.size20Bold : gStyles.size20
    } else if (size === 22) {
      if (centerText)
        return bold ? gStyles.size22CenteredBold : gStyles.size22Centered
      return bold ? gStyles.size22Bold : gStyles.size22
    } else if (size === 24) {
      if (centerText)
        return bold ? gStyles.size24CenteredBold : gStyles.size24Centered
      return bold ? gStyles.size24Bold : gStyles.size24
    }

    return gStyles.defaultText
  })()

  const txtNode = (
    <RNText ellipsizeMode="tail" numberOfLines={numberOfLines} style={txtStyle}>
      {children}
    </RNText>
  )

  if ((centerText && rn.Platform.OS === 'ios') || grow) {
    /* textAlignVertical not working in iOS as of May 2024 */
    return (
      <rn.View
        style={grow ? gStyles.centeredTextWrapperIOSGrow : gs.deadCenter}
      >
        {txtNode}
      </rn.View>
    )
  }

  return txtNode
})

const themedStyles = gs.ThemedStyleSheet.create((t, on) => {
  const defaultText = {
    color: t[on].color,
    fontFamily: t.fontFamily,
    fontWeight: '300' as const,
    textAlign: 'left',
    ...gs.userSelectNone
  } as const
  const defaultBold = { ...defaultText, fontWeight: '600' } as const

  const centeredText = {
    ...defaultText,
    textAlign: 'center',
    textAlignVertical: 'center'
  } as const
  const centeredBold = { ...centeredText, ...defaultBold }

  const size8 = { ...defaultText, fontSize: 8 }
  const size10 = { ...defaultText, fontSize: 10 }
  const size12 = { ...defaultText, fontSize: 12 }
  const size14 = { ...defaultText, fontSize: 14 }
  const size16 = { ...defaultText, fontSize: 16 }
  const size18 = { ...defaultText, fontSize: 18 }
  const size20 = { ...defaultText, fontSize: 20 }
  const size22 = { ...defaultText, fontSize: 22 }
  const size24 = { ...defaultText, fontSize: 24 }
  const size8Bold = { ...size8, ...defaultBold }
  const size10Bold = { ...size10, ...defaultBold }
  const size12Bold = { ...size12, ...defaultBold }
  const size14Bold = { ...size14, ...defaultBold }
  const size16Bold = { ...size16, ...defaultBold }
  const size18Bold = { ...size18, ...defaultBold }
  const size20Bold = { ...size20, ...defaultBold }
  const size22Bold = { ...size22, ...defaultBold }
  const size24Bold = { ...size24, ...defaultBold }
  const size8Centered = { ...size8, ...centeredText }
  const size10Centered = { ...size10, ...centeredText }
  const size12Centered = { ...size12, ...centeredText }
  const size14Centered = { ...size14, ...centeredText }
  const size16Centered = { ...size16, ...centeredText }
  const size18Centered = { ...size18, ...centeredText }
  const size20Centered = { ...size20, ...centeredText }
  const size22Centered = { ...size22, ...centeredText }
  const size24Centered = { ...size24, ...centeredText }
  const size8CenteredBold = { ...size8Bold, ...centeredBold }
  const size10CenteredBold = { ...size10Bold, ...centeredBold }
  const size12CenteredBold = { ...size12Bold, ...centeredBold }
  const size14CenteredBold = { ...size14Bold, ...centeredBold }
  const size16CenteredBold = { ...size16Bold, ...centeredBold }
  const size18CenteredBold = { ...size18Bold, ...centeredBold }
  const size20CenteredBold = { ...size20Bold, ...centeredBold }
  const size22CenteredBold = { ...size22Bold, ...centeredBold }
  const size24CenteredBold = { ...size24Bold, ...centeredBold }

  return {
    centeredBold,
    centeredText,
    defaultText,
    defaultBold,
    centeredTextWrapperIOSGrow: {
      ...gs.deadCenter,
      ...gs.flexGrow
    },
    size8,
    size10,
    size12,
    size14,
    size16,
    size18,
    size20,
    size22,
    size24,
    size8Bold,
    size10Bold,
    size12Bold,
    size14Bold,
    size16Bold,
    size18Bold,
    size20Bold,
    size22Bold,
    size24Bold,
    size8Centered,
    size10Centered,
    size12Centered,
    size14Centered,
    size16Centered,
    size18Centered,
    size20Centered,
    size22Centered,
    size24Centered,
    size8CenteredBold,
    size10CenteredBold,
    size12CenteredBold,
    size14CenteredBold,
    size16CenteredBold,
    size18CenteredBold,
    size20CenteredBold,
    size22CenteredBold,
    size24CenteredBold,
    whiteColor: { color: '#FFF' }
  }
})
