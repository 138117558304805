import React from 'react'

import * as c from '../../../common'

import * as cp from '../../../app/components'
import * as gs from '../../../app/gStyles'
import gStyles from '../../global.module.css'

import styles from './Dialog.module.css'

export type DialogProps = c.r<{
  children: React.ReactNode
  noCanvas?: boolean
  onClose(): void
  opaque?: boolean
  open: boolean
  title?: string
}>

// let scrollY = '0'

export default React.memo<DialogProps>(function Dialog({
  children,
  noCanvas,
  onClose,
  opaque,
  open,
  title
}) {
  // React.useEffect(() => {
  //   if (open) {
  //     scrollY = document.body.style.top
  //     document.body.style.position = 'fixed'
  //     document.body.style.top = `-${scrollY}px`
  //   } else {
  //     document.body.style.position = ''
  //     document.body.style.top = ''
  //     window.scrollTo(0, parseInt(scrollY || '0') * -1)
  //   }
  // }, [open])
  const backdropClass = opaque ? styles['backdrop-opaque'] : styles['backdrop']
  return (
    <div
      className={open ? backdropClass : gStyles['display-none']}
      // onClick={onClose}
    >
      <div
        className={
          noCanvas ? styles['dialog-content'] : styles['dialog-content-canvas']
        }
      >
        {!noCanvas && (
          <>
            <div className={styles['dialog-header']}>
              {title && (
                <cp.Text bold centerText size={24}>
                  {title}
                </cp.Text>
              )}

              <div
                className={styles['close-container-canvas']}
                onClick={onClose}
              >
                <span className={styles['close']}>&times;</span>
              </div>
            </div>

            <div className={gStyles['pad-v-12']} />
          </>
        )}

        {children}
      </div>

      {noCanvas && (
        <div className={styles['close-container']} onClick={onClose}>
          <span className={styles['close']}>&times;</span>
        </div>
      )}

      {gs.rowGap256}
    </div>
  )
})
