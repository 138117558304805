import React from 'react'

import * as c from '../../../common'
import {
  Customer,
  dispatch,
  efficiencyToInputs,
  EMPTY_FN,
  makeSelectCustomer,
  SelectCustomerParams,
  subToSingleCustomer,
  unSubFromSingleCustomer,
  useSelector,
  VoidFn
} from '../../../common'

export interface TotalCostProps {
  type: 'Cost' | 'Customer'
  customerID: string
}

export default React.memo<TotalCostProps>(function TotalCost({
  customerID,
  type
}) {
  React.useEffect((): VoidFn => {
    if (!customerID) {
      return EMPTY_FN
    }
    dispatch(
      subToSingleCustomer({
        customerID: customerID
      })
    )
    return () => {
      dispatch(
        unSubFromSingleCustomer({
          customerID
        })
      )
    }
  }, [customerID])

  const selectCustomer = React.useMemo(
    (): ReturnType<typeof makeSelectCustomer> => makeSelectCustomer(),
    []
  )

  const selectCustomerArgs = React.useMemo((): SelectCustomerParams => {
    if (typeof customerID === 'string') {
      return {
        customerID
      }
    } else {
      return {
        customerID: ''
      }
    }
  }, [customerID])

  const customer = useSelector(
    (_): Customer => selectCustomer(_, selectCustomerArgs)
  )

  const typeCost = React.useMemo(
    () => (type === 'Cost' ? 'customer_cost' : 'cost'),
    [type]
  )

  const totalCost = React.useMemo(() => {
    let acc: number = 0
    c.keys(efficiencyToInputs).forEach((key) => {
      if (customer[key as keyof Customer] === 'yes') {
        if (customer[(key + '_' + typeCost) as keyof Customer]) {
          const sum = customer[
            (key + '_' + typeCost) as keyof Customer
          ] as string
          acc += c.parseFloating(sum)
        }
      }
    })

    return acc
  }, [customer, typeCost])
  return (
    <div>
      <p>
        {type === 'Cost'
          ? 'Total Cost To Customer: ' + totalCost
          : 'Total Cost To Us: ' + totalCost}
      </p>
    </div>
  )
})
