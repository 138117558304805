import React from 'react'

import * as c from '../../common'
import * as r from '../../react-utils'

import Picker from './Picker'
import Radio from './OptionGroup'

export type ConnectedPickerProps = c.r<{
  customerID: string
  field: c.MultipleChoiceField
  inline?: boolean
  on: c.Surface
}>

export default React.memo<ConnectedPickerProps>(function ConnectedPicker({
  customerID,
  field,
  inline,
  on
}) {
  //#region global
  const [customer] = r.useCustomer(customerID)
  const closers = c.useSelector(c.selectClosers())
  const setters = c.useSelector(c.selectSetters())
  const insulationOpts = r.useInsulation(customerID)
  const windowColors = r.useWindowColors()
  const [roofLayoverOrTear] = r.useCustomerField(
    customerID,
    'roof_layover_or_tear'
  )
  const [batteryInstallationCompanies, batteryTypes, batterySizes] =
    r.useBattery(customerID)
  //#endregion global
  const label = c.getFieldLabel(field, customer)
  const [, writeFieldData] = r.useCustomerField(customerID, field)
  const fieldData = customer[field]

  const [data, required] = ((): [c.Opts, boolean] => {
    if (field === 'air_conditioner_current_tons') return [c.acTons, false]
    if (field === 'air_conditioner_unit_type') return [c.acSplitUnit, false]
    if (field === 'air_conditioner_new_tons') return [c.acTons, false]
    if (field === 'attic_insulation_type') return [insulationOpts, true]
    if (field === 'battery_size') return [batterySizes, true]
    if (field === 'battery_type') return [batteryTypes, true]
    if (field === 'homeRep') return [closers, true]
    if (field === 'main_panel_upgrade_needed_or_requested') {
      return [c.mainPanelUpgradeNeededOrRequested, true]
    }
    if (field === 'main_panel_upgrade_installation_company')
      return [batteryInstallationCompanies, false]
    if (field === 'new_windows_california_city') return [c.californiaCity, true]
    if (field === 'roof_layover_or_tear') return [c.roofLayoverOptions, true]
    if (
      field === 'roof_layers_how_many' &&
      roofLayoverOrTear === c.RoofWorkType.Layover
    ) {
      return [c.layoverLayersOpts, true]
    }
    if (
      field === 'roof_layers_how_many' &&
      roofLayoverOrTear === c.RoofWorkType.TearOff
    ) {
      return [c.tearOffLayerOpts, true]
    }
    if (field === 'roof_tear_material') {
      return [c.tearOffMaterialOpts, true]
    }
    if (field === 'solarRep') return [setters, true]
    if (field === 'solarCompany') return [c.companyOptions, true]
    if (field === 'new_windows_color') return [windowColors, true]

    return [c.EMPTY_ARRAY, false]
  })()

  const shouldDisable = c.shouldDisableInput(field, customer)
  const shouldHide = c.shouldHideInput(field, customer)
  const guideline = c.getFieldGuideline(customer, field)

  if (data.length <= 4 && r.isMobile && field !== 'battery_size') {
    return (
      <Radio
        disabled={shouldDisable}
        grow={c.fieldToGrow[field]}
        guideline={guideline?.text}
        guidelineType={guideline?.type}
        hide={shouldHide}
        label={label}
        on={on}
        onChange={writeFieldData}
        opts={data}
        required={required}
        value={fieldData}
      />
    )
  }

  return (
    <Picker
      disabled={shouldDisable}
      grow={c.fieldToGrow[field]}
      guideline={guideline?.text}
      guidelineType={guideline?.type}
      hide={shouldHide}
      inline={inline}
      label={label}
      on={on}
      onChange={writeFieldData}
      opts={data}
      required={required}
      value={fieldData}
    />
  )
})
