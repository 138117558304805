import React from 'react'
import * as rn from 'react-native'

import * as c from '../../common'

import * as gs from '../gStyles'

import Labeled from './Labeled'

export interface RadioProps {
  disabled?: boolean
  grow?: boolean
  guideline?: string
  guidelineType?: c.Guideline['type']
  hide?: boolean
  label?: false | string
  labelShowAsterisk?: boolean
  on: c.Surface
  onChange: (newValue: string) => void
  opts: c.Opts
  required?: boolean
  value?: string
}

export default React.memo<RadioProps>(function Radio({
  disabled,
  grow,
  guideline,
  guidelineType,
  hide,
  label,
  labelShowAsterisk,
  on,
  onChange,
  opts,
  required,
  value
}) {
  const styles = gs.useThemedStyleSheet(themedStyles, on)

  const currValueNotInOpts = React.useMemo(
    () => !!value && !opts.find((o) => o.value === value),
    [opts, value]
  )

  const renderOption = React.useCallback(
    (item: c.Opt, index: number, opts) => {
      return (
        <Option
          disabled={!!disabled || currValueNotInOpts}
          isFirst={index === 0}
          isLast={index === opts.length - 1}
          isSelected={value === item.value}
          label={item.label}
          key={item.value}
          on={on}
          onPress={onChange}
          value={item.value}
        />
      )
    },
    [currValueNotInOpts, disabled, on, onChange, value]
  )

  const rootEl = (
    <rn.View
      style={(() => {
        if (!grow && !label) return hide ? styles.rootHidden : styles.root
        return hide ? styles.rootGrowHidden : styles.rootGrow
      })()}
    >
      {opts.map(renderOption)}
    </rn.View>
  )

  if (label) {
    return (
      <Labeled
        disabled={disabled}
        grow={grow}
        guideline={
          guideline || (required && !value && !disabled && 'Select an option')
        }
        guidelineType={guideline ? guidelineType : 'warning'}
        label={label}
        on={on}
        showAsterisk={labelShowAsterisk}
      >
        {rootEl}
      </Labeled>
    )
  }

  return rootEl
})

interface OptionProps {
  readonly disabled: boolean
  readonly isFirst: boolean
  readonly isLast: boolean
  readonly isSelected: boolean
  readonly label: string
  readonly on: c.Surface
  readonly onPress: (value: c.Opt['value']) => void
  readonly value: c.Opt['value']
}

const Option = React.memo<OptionProps>(
  ({ disabled, isFirst, isLast, isSelected, label, on, onPress, value }) => {
    //#region styling
    const gss = gs.useThemedStyleSheet(themedStyles, on)
    /**
     * TODO: Good place to test useMemo() vs without. The number of deps is
     * lower than the times they are accessed, but the amount of comparisons is
     * close.
     */
    const optStyle = React.useMemo(() => {
      if (disabled && isSelected && isFirst && isLast) {
        return gss.optDisabledSelectedOnly
      }
      if (disabled && isSelected && isFirst) {
        return gss.optDisabledSelectedFirst
      }
      if (disabled && isSelected && isLast) return gss.optDisabledSelectedLast
      if (disabled && isSelected) return gss.optDisabledSelected

      if (disabled && isFirst && isLast) return gss.optDisabledOnly
      if (disabled && isFirst) return gss.optDisabledFirst
      if (disabled && isLast) return gss.optDisabledLast
      if (disabled) return gss.optDisabled

      if (isSelected && isFirst && isLast) return gss.optSelectedOnly
      if (isSelected && isFirst) return gss.optSelectedFirst
      if (isSelected && isLast) return gss.optSelectedLast
      if (isSelected) return gss.optSelected

      if (isFirst && isLast) return gss.optOnly
      if (isFirst) return gss.optFirst
      if (isLast) return gss.optLast
      return gss.opt
    }, [
      disabled,
      isSelected,
      isFirst,
      isLast,
      gss.optDisabledSelectedLast,
      gss.optDisabledSelected,
      gss.optDisabledOnly,
      gss.optDisabledFirst,
      gss.optDisabledLast,
      gss.optDisabled,
      gss.optSelectedOnly,
      gss.optSelectedFirst,
      gss.optSelectedLast,
      gss.optSelected,
      gss.optOnly,
      gss.optFirst,
      gss.optLast,
      gss.opt,
      gss.optDisabledSelectedOnly,
      gss.optDisabledSelectedFirst
    ])
    // const isFirst = i === 0
    // const isLast = i === totalOpts.length - 1
    //#endregion styling
    //#region global
    //#endregion global
    //#region local
    //#endregion local

    const handlePress = React.useCallback(
      () => void onPress(value),
      [value, onPress]
    )

    if (disabled) {
      return (
        <rn.View style={optStyle}>
          <rn.Text
            style={
              isSelected ? gss.optDisabledSelectedText : gss.optDisabledText
            }
          >
            {label}
          </rn.Text>
        </rn.View>
      )
    }
    return (
      <rn.TouchableOpacity onPress={handlePress} style={optStyle}>
        <rn.Text style={isSelected ? gss.optSelectedText : gss.optText}>
          {label}
        </rn.Text>
      </rn.TouchableOpacity>
    )
  }
)

const themedStyles = gs.ThemedStyleSheet.create((t, on) => {
  const first = {
    borderBottomLeftRadius: t.borderRadius,
    borderTopLeftRadius: t.borderRadius
  }
  const last = {
    borderBottomRightRadius: t.borderRadius,
    borderTopRightRadius: t.borderRadius
  }
  const only = { ...first, ...last }

  const opt = {
    ...gs.deadCenterGrow,
    backgroundColor: t[on].opaque,
    borderColor: t[on].opaque,
    paddingVertical: t.input[on].paddingVertical
  } as const
  const optText = {
    color: t[on].opaque__color,
    fontFamily: t[on].fontFamily,
    fontSize: 14,
    fontWeight: '400',
    // paddingHorizontal: 15,
    textAlign: 'center'
  } as const
  const optFirst = { ...opt, ...first }
  const optLast = { ...opt, ...last }
  const optOnly = { ...opt, ...only }

  const optSelected = {
    ...opt,
    backgroundColor: t[on].highlight,
    borderColor: t[on].highlight
  }
  const optSelectedFirst = { ...optSelected, ...first }
  const optSelectedLast = { ...optSelected, ...last }
  const optSelectedOnly = { ...optSelected, ...optOnly }

  const optDisabled = { ...opt, opacity: 0.5 }
  const optDisabledFirst = { ...optDisabled, ...first }
  const optDisabledLast = { ...optDisabled, ...last }
  const optDisabledOnly = { ...optDisabled, ...only }

  const optDisabledSelected = { ...optSelected, opacity: 0.5 }
  const optDisabledSelectedFirst = { ...optDisabledSelected, ...first }
  const optDisabledSelectedLast = { ...optDisabledSelected, ...last }
  const optDisabledSelectedOnly = { ...optDisabledSelected, ...only }

  return {
    disabled: { backgroundColor: t[on].opaque },
    opt,
    optFirst,
    optLast,
    optOnly,
    optText,
    optDisabled,
    optDisabledText: { ...optText, opacity: 0.5 },
    optDisabledFirst,
    optDisabledLast,
    optDisabledOnly,
    optDisabledSelected,
    optDisabledSelectedText: { ...optText, opacity: 0.5 },
    optDisabledSelectedFirst,
    optDisabledSelectedLast,
    optDisabledSelectedOnly,
    optSelected,
    optSelectedText: { ...optText, color: t[on].highlight__color },
    optSelectedFirst,
    optSelectedLast,
    optSelectedOnly,
    rootGrow: { ...gs.inputGrowNew, ...gs.row },
    rootGrowHidden: { ...gs.inputGrowNewHidden, ...gs.row },
    root: { ...gs.inputShrinkNew, ...gs.row },
    rootHidden: { ...gs.inputShrinkNewHidden, ...gs.row }
  }
})
