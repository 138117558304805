import React, { useCallback, useMemo } from 'react'
import {
  Insets,
  Text,
  TouchableOpacity,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
  useColorScheme
} from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'
import {
  efficiencyToCalculator,
  selectEfficiencyPrice,
  useSelector
} from '../../common'

import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from 'react-native-reanimated'
import FeaterIcons from 'react-native-vector-icons/Feather'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'

import * as gStyles from '../gStyles'

export interface PopoverProps {
  readonly customerID: string
  readonly field: c.CustomerField
  readonly onSelection: (type: PopoverOptionType) => void
}

const HIT_SLOP: Insets = {
  bottom: 15,
  left: 15,
  right: 15,
  top: 15
}

export default React.memo<React.PropsWithChildren<PopoverProps>>(
  function Popover({ customerID, field, onSelection }) {
    const [yesOrNo] = r.useCustomerField(customerID, field)
    const colorScheme = useColorScheme() || 'light'
    const dataPriceSelected = useSelector(selectEfficiencyPrice(field))

    const opacity = useSharedValue(0)

    const popoverOptions: PopoverOption[] = useMemo(
      () => [
        ...(dataPriceSelected
          ? [
              {
                disabled:
                  !dataPriceSelected?.nevada?.list.length ||
                  !dataPriceSelected?.california?.pricesByCity.length,
                icon: (
                  <MaterialCommunityIcons
                    color={c.themeTuple[colorScheme].canvas.highlight}
                    name="currency-usd"
                    size={25}
                  />
                ),
                label: 'Prices',
                type: 'view-preset-prices'
              }
            ]
          : ([] as any)),
        ...(field === 'main_panel_upgrade'
          ? [
              {
                icon: (
                  <MaterialCommunityIcons
                    color={c.themeTuple[colorScheme].canvas.highlight}
                    name="calculator"
                    size={25}
                  />
                ),
                label: 'Mpu calculator',
                type: 'mpu-calculator'
              }
            ]
          : ([] as any)),
        ...(Boolean(efficiencyToCalculator[field])
          ? [
              {
                disabled: yesOrNo === 'no',
                icon: (
                  <MaterialCommunityIcons
                    color={c.themeTuple[colorScheme].canvas.highlight}
                    name="refresh"
                    size={25}
                  />
                ),
                label: 'Refresh prices',
                type: 'refresh-price'
              }
            ]
          : []),
        {
          disabled: yesOrNo === 'no',
          icon: (
            <MaterialCommunityIcons
              color={c.themeTuple[colorScheme].canvas.highlight}
              name="delete"
              size={25}
            />
          ),
          label: 'Reset efficiency',
          type: 'reset-efficiency'
        }
      ],
      [colorScheme, dataPriceSelected, field, yesOrNo]
    )

    const showPopover = React.useCallback(() => {
      opacity.value = withTiming(opacity.value ? 0 : 1, {
        duration: 300
      })
    }, [opacity])

    const popoverStyle = useAnimatedStyle(() => {
      return {
        display: opacity.value > 0 ? 'flex' : 'none',
        opacity: opacity.value
      }
    })

    const contentPopoverStyle = useMemo(() => {
      return [
        popoverStyle,
        styles.contentContainer,
        colorScheme === 'dark' && styles.contentContainerDark
      ]
    }, [colorScheme, popoverStyle])

    const renderItem = useCallback(
      (option: PopoverOption, index: number) => {
        return (
          <OptionItem
            key={index}
            opacity={opacity}
            option={option}
            onSelection={onSelection}
          />
        )
      },
      [opacity, onSelection]
    )

    return (
      <View style={styles.container}>
        <TouchableOpacity
          hitSlop={HIT_SLOP}
          onPress={showPopover}
          style={styles.triggerButton}
        >
          <FeaterIcons
            color={c.themeTuple[colorScheme].paper.highlight}
            name="more-vertical"
            size={25}
          />
        </TouchableOpacity>

        <Animated.View
          style={contentPopoverStyle}
          renderToHardwareTextureAndroid
        >
          {popoverOptions.map(renderItem)}
        </Animated.View>
      </View>
    )
  }
)

interface OptionItemProps {
  readonly opacity: Animated.SharedValue<number>
  readonly option: PopoverOption
  readonly onSelection: (type: PopoverOption['type']) => void
}

const OptionItem = React.memo<OptionItemProps>(
  ({ opacity, option, onSelection }) => {
    const colorScheme = useColorScheme()

    const handleSelection = useCallback(
      (option: PopoverOption) => () => {
        if (option.disabled) {
          return
        }
        opacity.value = withTiming(0, { duration: 300 })
        opacity.value && onSelection(option.type)
      },
      [onSelection, opacity]
    )

    const contentOptionItemStyle = useMemo((): StyleProp<ViewStyle> => {
      return [
        styles.contentOption,
        option.disabled && styles.optionItemDisabled
      ]
    }, [option])

    const labelOptionStyle =
      colorScheme === 'dark' ? styles.labelOptionDark : styles.labelOption

    return (
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={handleSelection(option)}
        style={styles.option}
      >
        <View style={contentOptionItemStyle}>
          {option.icon && option.icon}
          <Text style={labelOptionStyle}>{option.label}</Text>
        </View>
      </TouchableOpacity>
    )
  }
)

const contentContainerBase = {
  backgroundColor: c.light.canvas.backgroundColor,
  borderRadius: c.light.borderRadius,
  justifyContent: 'center' as const,
  position: 'absolute' as const,
  paddingHorizontal: 10,
  paddingVertical: 10,
  right: 30,
  transform: [
    {
      translateY: -15
    }
  ],
  ...gStyles.shadowHalf,
  width: 190
}

const styles = StyleSheet.create({
  container: { alignItems: 'center', justifyContent: 'center' },
  contentContainer: contentContainerBase,
  contentContainerDark: {
    ...contentContainerBase,
    backgroundColor: c.dark.canvas.backgroundColor
  },
  contentOption: {
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: c.light.canvas.separator,
    flexDirection: 'row',
    paddingBottom: 6
  },
  labelOption: {
    color: c.light.canvas.color,
    fontFamily: c.light.fontFamily,
    marginLeft: 5
  },
  labelOptionDark: {
    color: c.dark.canvas.color,
    fontFamily: c.dark.fontFamily,
    marginLeft: 5
  },
  option: { paddingVertical: 5 },
  optionItemDisabled: { opacity: 0.4 },
  triggerButton: { borderRadius: 5 }
})

export type PopoverOptionType =
  | 'mpu-calculator'
  | 'refresh-price'
  | 'reset-efficiency'
  | 'view-preset-prices'

export interface PopoverOption {
  disabled?: boolean
  icon?: React.ReactElement
  label: string
  type: PopoverOptionType
}
