import React from 'react'
import { StyleProp, useColorScheme, View, ViewStyle } from 'react-native'

import * as c from '../../common'
import * as r from '../../react-utils'

import DropDownPicker from 'react-native-dropdown-picker'

import * as gs from '../gStyles'

import ConnectedInput from './ConnectedInput'
import Text from './Text'

export interface CashbackProps {
  customerID: string
}

export default React.memo<CashbackProps>(function Cashback({
  customerID
}): React.ReactElement {
  const colorScheme = useColorScheme()
  const styles = gs.useThemedStyleSheet(themedStyles)
  const rowGap = gs.useRowGap('paper')

  const [open, setOpen] = React.useState<boolean>(false)
  const [selectedValues, setSelectedValues] = React.useState<string[]>([])

  const hasPressed = React.useRef<boolean>(false)

  const selectCustomer = React.useMemo(
    (): ReturnType<typeof c.makeSelectCustomer> => c.makeSelectCustomer(),
    []
  )
  const selectCustomerArgs = React.useMemo((): c.SelectCustomerParams => {
    if (typeof customerID === 'string') {
      return {
        customerID
      }
    } else {
      return {
        customerID: ''
      }
    }
  }, [customerID])
  const customer = c.useSelector(
    (_): c.Customer => selectCustomer(_, selectCustomerArgs)
  )

  const cashbackOptions = React.useMemo(
    (): c.Opts => c.parseCashbackOptions(customer.cash_options),
    [customer]
  )

  const dropDownValueSelected = React.useMemo(
    (): string[] =>
      cashbackOptions.length
        ? cashbackOptions.map((option): string => option.value)
        : [],
    [cashbackOptions]
  )

  const cashbackTotal = React.useMemo(
    (): number => c.getTotalCashback(cashbackOptions, customer),
    [cashbackOptions, customer]
  )

  const cashbackShowError = React.useMemo((): boolean => {
    for (const cashbackOption of cashbackOptions) {
      const value = customer[cashbackOption.value as keyof c.Customer] as string
      if (!value) {
        return true
      }
    }
    return false
  }, [cashbackOptions, customer])

  const hideCashbackWarning =
    c.parseFloating(customer.cash_amount) === cashbackTotal ||
    cashbackShowError ||
    !customer.cash_amount ||
    !cashbackOptions.length

  const dropdownStyle = React.useMemo(
    (): StyleProp<ViewStyle>[] => [
      colorScheme === 'dark' ? styles.inputDark : styles.input
    ],
    [colorScheme, styles.input, styles.inputDark]
  )

  const flatListProps = React.useMemo(() => {
    return {
      contentContainerStyle:
        colorScheme === 'dark' && styles.containerDropDownStyleDark,
      nestedScrollEnabled: true,
      style: colorScheme === 'dark' && styles.containerDropDownStyleDark
    }
  }, [colorScheme, styles.containerDropDownStyleDark])

  const formattedSelectedItems = React.useCallback((): c.Opt[] => {
    const itemsSelected = c
      .cashBackOptions()
      .map((cashBackOption): c.Opt | null => {
        const index = selectedValues.findIndex(
          (value): boolean => value === cashBackOption.value
        )
        if (index > -1) {
          return { ...cashBackOption }
        }
        return null
      })
      .filter((cashBackOption): boolean => cashBackOption !== null) as c.Opt[]
    return itemsSelected
  }, [selectedValues])

  const onFocusInput = (): void => {
    if (!open) {
      return
    }
    setOpen(false)
  }

  const onPressSetValue = (
    value: React.Dispatch<React.SetStateAction<string[]>>
  ): void => {
    hasPressed.current = true
    const newValue = value as unknown as string[]
    setSelectedValues(newValue)
  }

  const onChangeSelector = React.useCallback((): void => {
    if (!hasPressed.current) {
      return
    }
    const cashOptions = formattedSelectedItems()

    c.updateCustomer(customerID, {
      cash_options: JSON.stringify(cashOptions)
    })
  }, [customerID, formattedSelectedItems])

  React.useEffect((): void => {
    onChangeSelector()
  }, [onChangeSelector])

  return (
    <>
      <ConnectedInput customerID={customerID} field="cash_amount" on="paper" />

      {rowGap}

      {!!customer.cash_amount && (
        <DropDownPicker
          dropDownDirection="TOP"
          flatListProps={flatListProps}
          items={c.cashBackOptions()}
          labelStyle={
            colorScheme === 'dark'
              ? styles.containerDropDownStyleDark
              : styles.containerDropDownStyle
          }
          listItemContainerStyle={
            colorScheme === 'dark' && styles.containerDropDownStyleDark
          }
          mode="BADGE"
          multiple={true}
          placeholder="Cashback Efficiencies"
          setOpen={setOpen}
          setValue={onPressSetValue}
          theme={colorScheme === 'dark' ? 'DARK' : 'LIGHT'}
          open={open}
          style={dropdownStyle}
          value={dropDownValueSelected}
        />
      )}

      {rowGap}

      {!!customer.cash_amount && !hideCashbackWarning && (
        <View style={styles.warningHeader}>
          <Text bold size={16}>
            The amounts entered don't add up to the cashback amount above
          </Text>
        </View>
      )}

      {!!customer.cash_amount && (
        <View
          style={
            hideCashbackWarning
              ? styles.warningContainerHidden
              : styles.warningContainer
          }
        >
          {cashbackOptions.map(
            (option): React.ReactElement => (
              <React.Fragment key={option.label}>
                {rowGap}

                <ConnectedInput
                  customerID={customerID}
                  field={option.value as keyof c.Customer}
                  keyboardType="numeric"
                  on="paper"
                  onFocus={onFocusInput}
                  placeholder="e.g. 4500"
                />
              </React.Fragment>
            )
          )}
        </View>
      )}
    </>
  )
})

const inputBase = {
  backgroundColor: c.light.canvas.backgroundColor,
  borderRadius: 10,
  borderWidth: 0,
  color: c.light.canvas.color,
  fontFamily: c.themeTuple.light.fontFamily,
  minHeight: 40,
  paddingHorizontal: 10,
  paddingVertical: 5
} as const

const themedStyles = gs.ThemedStyleSheet.create((t) => {
  const warningContainer = {
    ...gs.width100,
    alignItems: 'flex-end',
    borderColor: t.warning,
    borderBottomLeftRadius: t.paper.borderRadius,
    borderBottomRightRadius: t.paper.borderRadius,
    borderWidth: 4,
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: t.paper.gap,
    padding: 12
  } as const
  return {
    containerDropDownStyle: {
      backgroundColor: c.light.canvas.backgroundColor,
      color: c.light.canvas.color
    },
    containerDropDownStyleDark: {
      backgroundColor: c.dark.canvas.backgroundColor,
      color: c.light.canvas.color
    },
    input: inputBase,
    inputDark: {
      ...inputBase,
      backgroundColor: c.dark.canvas.backgroundColor,
      color: c.dark.canvas.color
    },
    warningContainer,
    warningContainerHidden: { ...warningContainer, borderWidth: 0, padding: 0 },
    warningHeader: {
      backgroundColor: t.warning,
      borderTopLeftRadius: t.paper.borderRadius,
      borderTopRightRadius: t.paper.borderRadius,
      marginBottom: r.isWeb ? -Number(t.paper.gap) * 2 : undefined,
      paddingHorizontal: 16,
      paddingVertical: 24,
      width: '100%'
    }
  }
})
